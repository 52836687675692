import React from 'react'
import FeedItem from './feedItem.js'

const Feed = ({ feedItems }) => {
  return (
    <div className="feed">
      {feedItems && feedItems.map((data) => {
        //  Make sure it has a project attached
        return (data.node.project ?
          <FeedItem
            image={data.node.image}
            project={data.node.project}
            key={data.node.id} 
          />
        : null)
      })}
    </div>
  )
}

export default Feed